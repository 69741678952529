import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  permisos?: {};
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}


const menu2 = {
  tanatorios: 0,
  secciones: 0,
  esquelas: 0
};


const MENUITEMS = [
  {
    state: 'pedidos',
    name: 'Pedidos',
    type: 'link',
    icon: 'shopping_cart',
    permisos: 1
  },
  {
    state: 'productos',
    name: 'Productos',
    type: 'link',
    icon: 'description',
    permisos: 1
  },
  {
    state: 'tanatorios',
    name: 'Tanatorios',
    type: 'link',
    icon: 'home',
    permisos: 1
  },
  {
    state: 'funerarias',
    name: 'Funerarias',
    type: 'link',
    icon: 'content_copy',
    permisos: 1
  },
  {
    state: 'esquelas',
    name: 'Esquelas',
    type: 'link',
    icon: 'add',
    permisos: 1
  },
  {
    state: 'secciones',
    name: 'Secciones Lugo',
    type: 'link',
    icon: 'content_copy',
    permisos: 1
  },
  {
    state: 'seccioneswebs',
    name: 'Secciones Webs',
    type: 'link',
    icon: 'content_copy',
    permisos: 1
  },
  {
    state: 'webs',
    name: 'Webs',
    type: 'link',
    icon: 'https',
    permisos: 1
  },
  {
    state: 'concello',
    name: 'Concello',
    type: 'link',
    icon: 'language',
    permisos: 1
  },
  {
    state: 'parroquias',
    name: 'Parroquias',
    type: 'link',
    icon: 'language',
    permisos: 1
  },
  {
    state: 'iglesias',
    name: 'Iglesias',
    type: 'link',
    icon: 'language',
    permisos: 1
  },
  {
    state: 'cementerios',
    name: 'Cementerios',
    type: 'link',
    icon: 'language',
    permisos: 1
  }
];


// content_copy



@Injectable()
export class MenuItems {
  menu2: {};
  getMenuitem(): Menu[] {



    return MENUITEMS;
  }
}
