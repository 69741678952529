import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { HomeComponent } from './home/home.component';
export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/authentication/login',
        pathMatch: 'full'
      },
      {
        path: 'home',
        redirectTo: '/dashboards/dashboard1',
        pathMatch: 'full'
      },
      {
        path: 'dashboards',
        loadChildren: './dashboards/dashboards.module#DashboardsModule'
      },
      {
        path: 'secciones',
        loadChildren:
          './secciones/secciones.module#SeccionesComponentsModule'
      },
      {
        path: 'seccioneswebs',
        loadChildren:
          './seccioneswebs/secciones.module#SeccionesComponentsModule'
      },
      {
        path: 'cementerios',
        loadChildren:
          './cementerios/cementerios.module#CementeriosComponentsModule'
      },
      {
        path: 'concello',
        loadChildren:
          './concello/concello.module#ConcelloComponentsModule'
      },
      {
        path: 'parroquias',
        loadChildren:
          './parroquias/parroquias.module#ParroquiasComponentsModule'
      },
      {
        path: 'iglesias',
        loadChildren:
          './iglesias/iglesias.module#IglesiasComponentsModule'
      },
      {
        path: 'esquelas',
        loadChildren:
          './esquelas/esquelas.module#EsquelasComponentsModule'
      },
      {
        path: 'tanatorios',
        loadChildren:
          './tanatorios/tanatorios.module#TanatoriosComponentsModule'
      },
      {
        path: 'faqs',
        loadChildren:
          './faqs/faqs.module#FaqsComponentsModule'
      },
      {
        path: 'atributos',
        loadChildren:
          './atributos/atributos.module#AtributosComponentsModule'
      },
      {
        path: 'noticias',
        loadChildren:
          './noticias/noticias.module#NoticiasComponentsModule'
      },
      {
        path: 'pedidos',
        loadChildren:
          './pedidos/pedidos.module#PedidosComponentsModule'
      },
      {
        path: 'tiendas_categorias',
        loadChildren:
          './tiendas_categorias/tiendas_categorias.module#TiendasCategoriasComponentsModule'
      },
      {
        path: 'funerarias',
        loadChildren:
          './funerarias/funerarias.module#FunerariasComponentsModule'
      },
      {
        path: 'productos',
        loadChildren:
          './productos/productos.module#ProductosComponentsModule'
      },
      {
        path: 'webs',
        loadChildren:
          './webs/webs.module#WebsComponentsModule'
      },
      {
        path: 'idiomas',
        loadChildren:
          './idiomas/idiomas.module#IdiomasComponentsModule'
      },
      {
        path: 'trabajadores',
        loadChildren:
          './trabajadores/trabajadores.module#TrabajadoresComponentsModule'
      }
    ]
  },
  {
    path: '',
    component: AppBlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren:
          './authentication/authentication.module#AuthenticationModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'authentication/404'
  }
];
