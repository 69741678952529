import { Component } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ActivatedRoute,Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  public config: PerfectScrollbarConfigInterface = {};
  // This is for Notifications
  menu2sup:any;
  tanatorios:any;
  form_tanatorio_principal = new FormGroup ({
    empresa: new FormControl()
  });

  constructor(private router: Router) {
    this.menu2sup = JSON.parse(localStorage.getItem('rol')); 
    this.tanatorios = JSON.parse(localStorage.getItem('tanatorios_listado'));

    
  }

  salir(){
    localStorage.setItem('token','');
    localStorage.setItem('permisos','');
    
    this.router.navigate(['/authentication/login']);
  }
  
  
}

